.main-container-callmanagement {
  /* background-color: red; */
  height: 500px;
  width: 100%;
  overflow-y: scroll;
}
.image-upload-main{
   position:absolute;
   display: block;
   align-self: center;
   background-color: #fff;
   width: 100%;
   height: 100%;
}
table button{
  font-size: 12px;

}