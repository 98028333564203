@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

*{
  font-family: 'Roboto', sans-serif;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* *{
  font-family: 'PT Sans', sans-serif;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

th{
  background-color: black;
  color: antiquewhite;
  text-transform: uppercase;
}
td{
border:solid gainsboro 1px;
padding: 0.2rem;
word-wrap: break-word;
height: auto;

}
u{
  cursor: pointer;
}

table{
  background-color: whitesmoke;
border-radius: 10px;

}
.green-dot{
  height: 0.5rem;
  width: 0.5rem;
  background-color: lightgreen;
  border-radius: 50%;
  display: inline-block;
}
.red-dot{
 height: 0.5rem;
  width: 0.5rem;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

DropdownMenu{
  max-height: 300px;
  overflow-y: scroll;
}