
.container {
  text-align: center;
}
.loadinggif {
  height: 2rem;
}
/* .removebtn{
    visibility: hidden;
} */
.navigation-main {
  position: absolute;
  left: 0;
  height: 100vh;
  width: 10%;
  max-width: 80px;
  background-color: #303f9f;
}
.navigation-main button {
  color: black;
  background-color: #fff;
  word-wrap: break-word;
  width: 100%;
  height: 5rem;
}
.form-main {
  margin-left: 7%;
  height: 100%;
  text-align: center;
  margin-top: 2%;
}
.main-container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
}
