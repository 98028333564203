.location {
  word-wrap: break-word;
  width: 50px;
  height: auto;
  border: 1px solid salmon;
  padding: 10px;
}

.location h4 {
  font-weight: bold;
  letter-spacing: 3px;
}
.total-calllocks {
  background-color: black;
  color: aliceblue;
  padding: 10px;
  height: auto;
  display: flex;
  flex-direction: column;
}
.total-calllocks h {
  font-size: 4rem;
  color: lightcoral;
}
.calllock-history {
  /* padding: 10px; */
  background-color: #2980b9;
  /* height: 100%; */
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
}
.calllock-history h {
  font-size: 2rem;
  color: whitesmoke;
}
.call-history {
  margin-left: 10px;
  justify-content: space-around;
}
.call-history h {
  font-size: 0.8rem;
}
.calllock-history h6 {
  color: black;
}
.calllock-history table,
th {
  color: black;
  background-color: transparent;
  font-size: 0.7rem;
  margin-left: 25px;
}
.calllock-history td {
  font-size: 0.7rem;
  border: none;
  color: whitesmoke;
}
.calllock-history table {
  width: 100%;
}
.calllock-history h6 {
  text-transform: uppercase;
  font-weight: bold;
  margin: 10px 0 10px 0;
}
.asset-owner {
  background-color: black;
  color: white;
}
.asset-owner h2 {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.userdetail-main {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  flex-direction: column;
}
.userdetails-credentials-main {
  background-color: #6d99a2;
  color: white;
  padding: 5px;
}
.location-main {
  overflow: auto;
}
.location-main-inner h5 {
  align-self: center;
  margin: 1rem 2px;
  white-space: nowrap;
}
.location-main-inner {
  border: 1px solid black;
  margin-left: 1px;
}
.table-container-main{
  overflow-y: scroll;
  margin: 50px;
  height: 70vh;
}
.main-container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}