@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: "PT Sans", sans-serif;
}

*{
  font-family: 'Roboto', sans-serif;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* *{
  font-family: 'PT Sans', sans-serif;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

th{
  background-color: black;
  color: antiquewhite;
  text-transform: uppercase;
}
td{
border:solid gainsboro 1px;
padding: 0.2rem;
word-wrap: break-word;
height: auto;

}
u{
  cursor: pointer;
}

table{
  background-color: whitesmoke;
border-radius: 10px;

}
.green-dot{
  height: 0.5rem;
  width: 0.5rem;
  background-color: lightgreen;
  border-radius: 50%;
  display: inline-block;
}
.red-dot{
 height: 0.5rem;
  width: 0.5rem;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

DropdownMenu{
  max-height: 300px;
  overflow-y: scroll;
}


.container {
  text-align: center;
}
.loadinggif {
  height: 2rem;
}
/* .removebtn{
    visibility: hidden;
} */
.navigation-main {
  position: absolute;
  left: 0;
  height: 100vh;
  width: 10%;
  max-width: 80px;
  background-color: #303f9f;
}
.navigation-main button {
  color: black;
  background-color: #fff;
  word-wrap: break-word;
  width: 100%;
  height: 5rem;
}
.form-main {
  margin-left: 7%;
  height: 100%;
  text-align: center;
  margin-top: 2%;
}
.main-container {
  height: 100vh;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

ul{
    list-style: none;
}
.popupmain{
      position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background:wheat;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: autoWWw
}

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}
.image-container{
  height: 100%;
}

.popup-image{
  position: relative;
  height: 100%;
}
.main-container-callmanagement {
  /* background-color: red; */
  height: 500px;
  width: 100%;
  overflow-y: scroll;
}
.image-upload-main{
   position:absolute;
   display: block;
   -webkit-align-self: center;
           align-self: center;
   background-color: #fff;
   width: 100%;
   height: 100%;
}
table button{
  font-size: 12px;

}
.location {
  word-wrap: break-word;
  width: 50px;
  height: auto;
  border: 1px solid salmon;
  padding: 10px;
}

.location h4 {
  font-weight: bold;
  letter-spacing: 3px;
}
.total-calllocks {
  background-color: black;
  color: aliceblue;
  padding: 10px;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.total-calllocks h {
  font-size: 4rem;
  color: lightcoral;
}
.calllock-history {
  /* padding: 10px; */
  background-color: #2980b9;
  /* height: 100%; */
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
}
.calllock-history h {
  font-size: 2rem;
  color: whitesmoke;
}
.call-history {
  margin-left: 10px;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.call-history h {
  font-size: 0.8rem;
}
.calllock-history h6 {
  color: black;
}
.calllock-history table,
th {
  color: black;
  background-color: transparent;
  font-size: 0.7rem;
  margin-left: 25px;
}
.calllock-history td {
  font-size: 0.7rem;
  border: none;
  color: whitesmoke;
}
.calllock-history table {
  width: 100%;
}
.calllock-history h6 {
  text-transform: uppercase;
  font-weight: bold;
  margin: 10px 0 10px 0;
}
.asset-owner {
  background-color: black;
  color: white;
}
.asset-owner h2 {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.userdetail-main {
  background-color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  height: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.userdetails-credentials-main {
  background-color: #6d99a2;
  color: white;
  padding: 5px;
}
.location-main {
  overflow: auto;
}
.location-main-inner h5 {
  -webkit-align-self: center;
          align-self: center;
  margin: 1rem 2px;
  white-space: nowrap;
}
.location-main-inner {
  border: 1px solid black;
  margin-left: 1px;
}
.table-container-main{
  overflow-y: scroll;
  margin: 50px;
  height: 70vh;
}
.main-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
